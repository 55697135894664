<template>
  <div>
    <vb-reset-password />
  </div>
</template>
<script>
import VbResetPassword from '@/@vb/components/Auth/ResetPassword'
export default {
  components: {
    VbResetPassword,
  },
}
</script>
