<template>
  <div class="mt-5 pt-2">
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-32 mb-3">Обновить пароль</div>
      <a-form ref="registerForm" :model="resetForm" :rules="rules" layout="vertical" class="mb-4">
        <a-form-item name="password">
          <a-input v-model:value="resetForm.password" placeholder="Пароль" />
        </a-form-item>
        <a-form-item name="confirm">
          <a-input v-model:value="resetForm.confirm" placeholder="Подтверждение пароля" />
        </a-form-item>
        <a-button :loading="loading" @click="resetPassword" type="primary" class="text-center w-100" html-type="submit">
          <strong>Обновить</strong>
        </a-button>
      </a-form>
      <router-link to="/auth/login" class="vb__utils__link">
        <i class="fe fe-arrow-left mr-1 align-middle" />
        Вернуться на страницу авторизации
      </router-link>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref } from 'vue'
import apiClient from '@/services/axios'
import { notification } from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'

const loading = ref(false)
const route = useRoute()
const router = useRouter()
const rules = {
  password: [
    {
      required: true,
      message: 'Пожалуйста ведите новый пароль!',
      trigger: 'change',
    },
  ],
  confirm: [
    {
      required: true,
      message: 'Пожалуйста ведите подтверждение пароля!',
      trigger: 'change',
    },
  ],
}
const resetForm = reactive({
  password: '',
  confirm: ''
})
const resetPassword = () => {
  if (!resetForm.password || !resetForm.confirm) {
    return false
  }
  loading.value = true
  apiClient.post(`auth/update-password`, {
    auth_key: route.params.key,
    password: resetForm.password,
    confirm: resetForm.confirm
  }).then(res => {
    if (res?.data?.data) {
      router.push({ name: 'login'}).finally(() => {
        notification.success({
          message: 'Пароль успешно обновлен',
        })
      })
    } else {
      notification.warning({
        message: 'Не удалось обновить пароль',
      })
    }
  }).catch(err => {
    console.log(err)
    notification.warning({
      message: 'Не удалось обновить пароль',
    })
  }).finally(() => {
    loading.value = false
  })
}
</script>
<style lang="scss" module>
@import '@/@vb/components/Auth/style.module.scss';
</style>
